import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Category } from 'src/types/category';
import { ProjectContract } from 'src/types/contract';
import { LabCategory } from 'src/types/labCategory';

import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Skeleton from '@material-ui/lab/Skeleton';

const allLabels = {
  createTitle: 'Adauga mapare noua',
  updateTitle: 'Editeaza maparea',
  category: 'Categorie',
  template: 'Template',
  submitLabel: 'Salveaza',
  create: {
    success: 'Mapare adaugata cu succes',
    fail: 'Problema adaugare mapare'
  },
  update: {
    success: 'Mapare actualizata cu succes',
    fail: 'Problema actualizare mapare'
  },
  errors: {
    category: 'Categoria este obligatorie!',
    template: 'Template-ul este obligatoriu!'
  }
};

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra: { labId: string; templateType: 'order' | 'pvp' | 'report' | 'worksheet'; labCatId?: string };
}

const PaymentModal: FC<Props> = ({ extra, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [labCategory, setLabCategory] = useState<LabCategory>(extra?.labCatId ? null : new LabCategory());
  const [labCategoryLoading, setLabCategoryLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [projectContracts, setProjectContracts] = useState<ProjectContract[]>([]);
  const [errors, setErrors] = useState<{ [field: string]: boolean }>({
    category: false,
    template: false
  });

  const getLaboratory = useCallback(
    async () => {
      const lab = await api.labs.get(extra.labId);
      setLabCategory({ ...labCategory, laboratory: lab });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extra.labId]
  );

  const getLabCategory = useCallback(
    async () => {
      setLabCategoryLoading(true);
      const labCat = await api.labCategories.get(extra.labCatId);
      setLabCategory(labCat);
      setLabCategoryLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extra.labCatId]
  );

  const getCategories = useCallback(async () => {
    setCategories((await api.categories.list()).result);
  }, []);

  const getProjectContracts = useCallback(async () => {
    if (extra.templateType === 'order') {
      setProjectContracts((await api.projectContracts.listOrderTemplates(extra.labId)).result);
    } else if (extra.templateType === 'pvp') {
      setProjectContracts((await api.projectContracts.listPVPTemplates(extra.labId)).result);
    } else if (extra.templateType === 'report') {
      setProjectContracts((await api.projectContracts.listReportTemplates(extra.labId)).result);
    } else if (extra.templateType === 'worksheet') {
      setProjectContracts((await api.projectContracts.listWorksheetTemplates(extra.labId)).result);
    }
  }, [extra.labId, extra.templateType]);

  useEffect(() => {
    if (rest.visible) {
      if (extra.labCatId) {
        getLabCategory();
      } else {
        getLaboratory();
      }
      getCategories();
      getProjectContracts();
    } else {
      setLabCategory(null);
      setCategories([]);
      setProjectContracts([]);
      setErrors({ category: false, template: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.visible]);

  const categoryChangeHandler = (event) => {
    const cat = categories.find((c) => c.id === event.target.value);
    setLabCategory((prev) => ({ ...prev, category: cat }));
    setErrors((prev) => ({ ...prev, category: false }));
  };

  const projectContractChangeHandler = (event) => {
    const pc = projectContracts.find((c) => c.id === event.target.value);
    setLabCategory((prev) => ({ ...prev, projectContract: pc }));
    setErrors((prev) => ({ ...prev, template: false }));
  };

  const checkValid = () => {
    if (!labCategory?.category?.id) {
      setErrors((prev) => ({ ...prev, category: true }));
      return false;
    }
    if (!labCategory?.projectContract?.contract?.id) {
      setErrors((prev) => ({ ...prev, template: true }));
      return false;
    }
    return true;
  };

  const createHandler = async () => {
    if (!checkValid()) return;
    const payload = { ...labCategory };
    delete payload.laboratory.files;
    delete payload.laboratory.laboratoryProducts;
    delete payload.laboratory.workstations;
    delete payload.projectContract.laboratory;
    try {
      await api.labCategories.create(payload);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      enqueueSnackbar(allLabels.create.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${allLabels.create.fail}: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const updateHandler = async () => {
    if (!checkValid()) return;
    const payload = { ...labCategory };
    delete payload.laboratory.files;
    delete payload.laboratory.laboratoryProducts;
    delete payload.laboratory.workstations;
    delete payload.projectContract.laboratory;
    try {
      await api.labCategories.update(extra.labCatId, payload);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      enqueueSnackbar(allLabels.update.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${allLabels.update.fail}: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  return (
    <DialogAdvanced {...rest}>
      <Card>
        <CardHeader
          title={extra.labCatId ? allLabels.updateTitle : allLabels.createTitle}
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {labCategoryLoading ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <TextField
                  key={allLabels.category}
                  label={allLabels.category}
                  name={allLabels.category}
                  fullWidth
                  onChange={categoryChangeHandler}
                  value={labCategory?.category?.id || ''}
                  select
                  variant="outlined"
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: !!labCategory?.category?.id }}
                  error={!!errors.category}
                  helperText={!!errors.category && allLabels.errors.category}
                >
                  <option key="" value="" />
                  {categories.map((cat: Category) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {labCategoryLoading ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <TextField
                  key={allLabels.template}
                  label={allLabels.template}
                  name={allLabels.template}
                  fullWidth
                  onChange={projectContractChangeHandler}
                  value={labCategory?.projectContract?.id || ''}
                  select
                  variant="outlined"
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: !!labCategory?.projectContract?.id }}
                  error={!!errors.template}
                  helperText={!!errors.template && allLabels.errors.template}
                >
                  <option key="" value="" />
                  {projectContracts.map((pc: ProjectContract) => (
                    <option key={pc.id} value={pc.id}>
                      {pc.contract.title}
                    </option>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box m={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={extra.labCatId ? updateHandler : createHandler}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
          >
            {allLabels.submitLabel}
          </Button>
        </Box>
      </Card>
    </DialogAdvanced>
  );
};

export default PaymentModal;
