import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import DocumentForm from 'src/components/DocumentForm';
import useGlobalModal, { MODALS } from 'src/hooks/useGlobalModal';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Category } from 'src/types/category';
import { DocumentAttributes, ProjectContract, TemplateAttribute } from 'src/types/contract';
import { LabCategory } from 'src/types/labCategory';
import { Order } from 'src/types/order';
import { Project } from 'src/types/project';
import { User } from 'src/types/user';
import { Workstation } from 'src/types/workstation';
import formatDateUtil from 'src/utils/formatDate';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Skeleton from '@material-ui/lab/Skeleton';

const PVP_MANDATORY_FIELDS = [{ key: 'data_document', type: 'date' }];

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  card: {}
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra: { project: Project; user: User };
}

const ProjectPVPCreateModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useGlobalModal();
  const [deps, setDeps] = useState<{
    categories: Category[];
    labCategories: LabCategory[];
    workstations: Workstation[];
    orders: Order[];
    project: Project;
  }>({
    categories: [],
    labCategories: [],
    workstations: [],
    orders: [],
    project: null
  });
  const [attributes, setAttributes] = useState<DocumentAttributes>({});
  const [error, setError] = useState<{ [key: string]: boolean }>({});
  const [category, setCategory] = useState<Category>(null);
  const [workstation, setWorkstation] = useState<Workstation>(extra.user.workstation);
  const [template, setTemplate] = useState<ProjectContract>(null);
  const [templates, setTemplates] = useState<ProjectContract[]>([]);
  const [order, setOrder] = useState<Order>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDependences, setLoadingDependences] = useState<boolean>(false);

  const isValid = (): boolean => {
    const newErrors = {};
    PVP_MANDATORY_FIELDS.forEach((el: TemplateAttribute) => {
      if (!attributes[el.key]) {
        newErrors[el.key] = true;
      }
    });
    if (!workstation) {
      // eslint-disable-next-line dot-notation
      newErrors['workstation'] = true;
    }
    setError(newErrors);
    return !Object.keys(newErrors)?.length;
  };

  const saveHandler = async () => {
    if (!isValid()) return;
    try {
      setLoading(true);
      attributes['extra.projectId'] = extra?.project?.id;
      attributes['extra.categoryId'] = category.id;
      attributes['extra.workstationId'] = workstation.id;
      attributes['extra.templateId'] = template.id;
      attributes['extra.orderId'] = order.id;
      attributes['extra.clientId'] = deps.project?.client?.id;
      attributes['extra.clientCompanyName'] = deps.project?.client?.companyName;
      const pc = await api.projectContracts.generatePVPWA(attributes, template.id);
      enqueueSnackbar('PVP creat cu success', { variant: 'success' });
      rest.setNotify(pc.id);
      rest.cancel();
      openModal(MODALS.TEXT_EDITOR, {
        projectContractId: pc.id,
        canEdit: true,
        viewMode: true,
        title: 'PVP'
      });
    } catch (e) {
      let errorMessage = e?.message;
      try {
        errorMessage = JSON.parse(e?.message);
        // eslint-disable-next-line no-empty
      } catch {}

      if (errorMessage?.message?.includes('duplicate key value violates unique constraint')) {
        enqueueSnackbar('Nu se poate adauga inainte de primul PVP', { variant: 'error' });
      } else {
        enqueueSnackbar(`Problema adaugare PVP: ${e?.message}`, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const loadDependences = useCallback(async () => {
    try {
      setLoadingDependences(true);
      const lab = await api.labs.get(extra.project?.laboratory?.id);
      const workstations = [...lab.workstations];
      const categories = (await api.categories.list()).result?.filter((cat: Category) => cat.forPvp);
      const labCategories = (await api.labCategories.listByLab(`${extra?.project?.laboratory?.id}?type=pvp_template`))
        .result;
      const orders = (await api.orders.ordersByProject(extra?.project?.id)).result?.sort((a, b) =>
        a.docDate < b.docDate ? -1 : 1
      );
      const project = await api.projects.get(extra.project.id);
      setDeps({ categories, labCategories, workstations, orders, project });
      setWorkstation(extra.user.workstation);
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setLoadingDependences(false);
    }
  }, [extra]);

  useEffect(() => {
    if (!rest.visible) {
      setAttributes({});
      setCategory(null);
      setWorkstation(null);
      setTemplate(null);
      setOrder(null);
      setError({});
    } else {
      loadDependences();
    }
  }, [rest.visible, loadDependences]);

  const handleChangeCategory = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const cat = deps?.categories.find((p) => p.id === event.target.value) || null;
    if (cat) {
      const labCats = deps?.labCategories.filter((lc) => lc.category.id === cat.id);
      if (labCats.length) {
        const newTemplates = labCats.map((lc) => lc.projectContract);
        setTemplates(newTemplates);
        if (newTemplates.length === 1) {
          setTemplate(newTemplates[0]);
        } else {
          setTemplate(null);
        }
      } else {
        setTemplates([]);
        setTemplate(null);
      }
      setCategory(cat);
    }
  };

  const handleChangeTemplate = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const selectedTemplate = templates.find((t) => t.id === event.target.value) || null;
    setTemplate(selectedTemplate);
  };

  const handleChangeWorkstation = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const work = deps?.workstations.find((w) => w.id === event.target.value) || null;
    setWorkstation(work);
    if (error?.workstation) setError({ ...error, workstation: false });
  };

  const resetError = (data: DocumentAttributes) => {
    const newError = { ...error };
    Object.keys(data).forEach((key: string) => {
      if (newError[key]) {
        newError[key] = false;
      }
    });
    setError(newError);
  };

  const handleChangeData = (data: DocumentAttributes) => {
    resetError(data);
    setAttributes(data);
  };

  const handleChangeOrder = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newOrder = deps?.orders.find((o) => o.id === event.target.value) || null;
    setOrder(newOrder);
  };

  const nrOfProbes = attributes['data.probe'] ? Number.parseInt(attributes['data.probe'].toString(), 10) : 0;
  const arrayOfProbes = nrOfProbes ? Array.from({ length: nrOfProbes }, (_, i) => i + 1) : [];

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga PVP"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {loadingDependences ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <TextField
                  fullWidth
                  label="Punct de lucru"
                  onChange={handleChangeWorkstation}
                  value={workstation?.id}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  error={error?.workstation}
                  helperText={error?.workstation && 'Punctul de lucru este obligatoriu!'}
                  disabled
                  InputLabelProps={{ shrink: !!workstation?.id }}
                >
                  <option key="" value="" />
                  {deps?.workstations &&
                    deps.workstations.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              {loadingDependences ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <>
                  <TextField
                    fullWidth
                    label="Categorie"
                    onChange={handleChangeCategory}
                    value={category?.id}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    InputLabelProps={{ shrink: !!category?.id }}
                  >
                    <option key="" value="" />
                    {deps?.categories &&
                      deps.categories.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                  {(!!category && !templates.length) || (template && !template?.contract?.tempAttributes) ? (
                    <Box>
                      <FormHelperText error>Aceasta categorie nu are un template definit!</FormHelperText>
                    </Box>
                  ) : null}
                </>
              )}
            </Grid>
            {templates.length ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Template"
                  onChange={handleChangeTemplate}
                  value={template?.id || ''}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: !!template?.id }}
                  disabled={templates.length === 1}
                >
                  <option key="" value="" />
                  {templates.map((pc: ProjectContract) => (
                    <option key={pc.id} value={pc.id}>
                      {pc.contract.title}
                    </option>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {category && template && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Selecteaza Comanda"
                  onChange={handleChangeOrder}
                  value={order?.id}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: !!order?.id }}
                >
                  <option key="" value="" />
                  {deps?.orders &&
                    deps.orders.map((option: Order) => (
                      <option key={option.id} value={option.id}>
                        {`${option.pno ? option.pno : option.no} / ${formatDateUtil(
                          `${option.docDate ? option.docDate : option.created}`,
                          true,
                          '.'
                        )}`}
                      </option>
                    ))}
                </TextField>
              </Grid>
            )}
            {order && template && template?.contract?.tempAttributes && (
              <>
                <DocumentForm
                  data={attributes}
                  error={error}
                  requiredFields={PVP_MANDATORY_FIELDS}
                  tempAttributes={template.contract.tempAttributes}
                  onChange={handleChangeData}
                />
                {arrayOfProbes.map((np) => (
                  <React.Fragment key={np}>
                    <DocumentForm
                      title={`Proba #${np.toString()}`}
                      data={attributes}
                      tempAttributes={template.contract.tempAttributes}
                      onChange={handleChangeData}
                      dataKey="probe."
                      index={np}
                    />
                  </React.Fragment>
                ))}
              </>
            )}
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={saveHandler}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={!template || !template?.contract?.tempAttributes || !order}
            loading={loading}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default ProjectPVPCreateModal;
