import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import HomeGuard from 'src/components/HomeGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import LoginGuard from 'src/components/LoginGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import { Routes } from 'src/types/routes';

import AuthClientGuard from './components/AuthClientGuard';
import ChangeLabGuard from './components/ChangeLabGuard';
import NoAccessGuard from './components/NoAccessGuard';
import ClientLayout from './layouts/ClientLayout';

export const renderRoutes = (routes: Routes = []): JSX.Element => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i.toString()}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} navigation={{ ...route.navigation, path: route.path }} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/download/:itemId',
    component: lazy(() => import('src/views/download/DownloadView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/connect',
    component: lazy(() => import('src/views/auth/ConnectView'))
  },
  {
    exact: true,
    guard: LoginGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/recover',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/reset-password/:token',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: NoAccessGuard,
    path: '/no-access',
    component: lazy(() => import('src/views/auth/NoAccessView'))
  },
  {
    exact: true,
    guard: ChangeLabGuard,
    path: '/set-workstation',
    component: lazy(() => import('src/views/auth/ChangeLabView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/management/users',
        navigation: {
          current: 'Lista utilizatori'
        },
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/app/management/users/:itemId',
        navigation: {
          current: 'Editare utilizator',
          links: [{ label: 'Lista utilizatori', url: '/app/management/users' }]
        },
        component: lazy(() => import('src/views/user/UserEditView'))
      },
      {
        exact: true,
        path: '/app/management/labs',
        navigation: {
          current: 'Lista laboratoare'
        },
        component: lazy(() => import('src/views/labs/LabListView'))
      },
      {
        exact: true,
        path: '/app/management/labs/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/app/management/labs/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/app/management/labs/:itemId/:tab',
        navigation: {
          current: 'Editare laborator',
          links: [{ label: 'Lista laboratoare', url: '/app/management/labs' }]
        },
        component: lazy(() => import('src/views/labs/LabEditView'))
      },
      {
        exact: true,
        path: '/app/management/labs/:parentId/products/:itemId',
        navigation: {
          current: 'Editare serviciu laborator',
          links: [
            { label: 'Lista laboratoare', url: '/app/management/labs' },
            { label: 'Editeaza laborator', url: '/app/management/labs', parent: true }
          ]
        },
        component: lazy(() => import('src/views/labs/LabProductEditView'))
      },
      {
        exact: true,
        path: '/app/management/categories',
        navigation: {
          current: 'Lista categorii'
        },
        component: lazy(() => import('src/views/categories/CategoryListView'))
      },
      {
        exact: true,
        path: '/app/management/categories/:itemId',
        navigation: {
          current: 'Editare categorie',
          links: [{ label: 'Lista categorii', url: '/app/management/categories' }]
        },
        component: lazy(() => import('src/views/categories/CategoryEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        navigation: {
          current: 'Lista servicii'
        },
        component: lazy(() => import('src/views/products/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/:itemId',
        navigation: {
          current: 'Editare serviciu',
          links: [{ label: 'Lista servicii', url: '/app/management/products' }]
        },
        component: lazy(() => import('src/views/products/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/management/clients',
        navigation: {
          current: 'Lista clienti'
        },
        component: lazy(() => import('src/views/clients/ClientListView'))
      },
      {
        exact: true,
        path: '/app/management/clients/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/app/management/clients/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/app/management/clients/:itemId/:tab',
        navigation: {
          current: 'Editare client',
          links: [{ label: 'Lista clienti', url: '/app/management/clients' }]
        },
        component: lazy(() => import('src/views/clients/ClientEditView'))
      },
      {
        exact: true,
        path: '/app/management/clients/:parentId/sites/:itemId',
        navigation: {
          current: 'Editare santier client',
          links: [
            { label: 'Lista clienti', url: '/app/management/clients' },
            { label: 'Editeaza client', url: '/app/management/clients', parent: true }
          ]
        },
        component: lazy(() => import('src/views/clients/ClientSiteEditView'))
      },
      {
        exact: true,
        path: '/app/management/sites',
        navigation: {
          current: 'Lista santiere'
        },
        component: lazy(() => import('src/views/sites/SiteListView'))
      },
      {
        exact: true,
        path: '/app/management/sites/:itemId',
        navigation: {
          current: 'Editare santier',
          links: [{ label: 'Lista santiere', url: '/app/management/sites' }]
        },
        component: lazy(() => import('src/views/sites/SiteEditView'))
      },
      {
        exact: true,
        path: '/app/management/roles',
        navigation: {
          current: 'Lista roluri'
        },
        component: lazy(() => import('src/views/roles/RoleListView'))
      },
      {
        exact: true,
        path: '/app/management/roles/:itemId',
        navigation: {
          current: 'Editare rol',
          links: [{ label: 'Lista roluri', url: '/app/management/roles' }]
        },
        component: lazy(() => import('src/views/roles/RoleEditView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/users" />
      },
      {
        exact: true,
        path: '/app/flux/projects',
        navigation: {
          current: 'Lista proiecte'
        },
        component: lazy(() => import('src/views/projects/ProjectListView'))
      },
      {
        exact: true,
        path: '/app/flux/projects/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/app/flux/projects/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/app/flux/projects/:itemId/:tab',
        navigation: {
          current: 'Editare proiect',
          links: [{ label: 'Lista proiecte', url: '/app/flux/projects' }]
        },
        component: lazy(() => import('src/views/projects/ProjectEditView'))
      },
      {
        exact: true,
        path: '/app/flux/projects/:itemId/:tab/:selectedId',
        navigation: {
          current: 'Editare proiect',
          links: [{ label: 'Lista proiecte', url: '/app/flux/projects' }]
        },
        component: lazy(() => import('src/views/projects/ProjectEditView'))
      },
      {
        exact: true,
        path: '/app/flux/emails',
        navigation: {
          current: 'Lista emails'
        },
        component: lazy(() => import('src/views/emails/EmailListView'))
      },
      {
        exact: true,
        path: '/app/flux/orders',
        navigation: {
          current: 'Lista comenzi'
        },
        component: lazy(() => import('src/views/orders/OrderListView'))
      },
      {
        exact: true,
        path: '/app/flux/orders/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/app/flux/orders/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/app/flux/orders/:itemId/:tab',
        navigation: {
          current: 'Editare comanda',
          links: [{ label: 'Lista comenzi', url: '/app/flux/orders' }]
        },
        component: lazy(() => import('src/views/orders/OrderEditView'))
      },

      {
        exact: true,
        path: '/app/flux/reports',
        navigation: {
          current: 'Lista rapoarte'
        },
        component: lazy(() => import('src/views/reports/ReportListView'))
      },
      {
        exact: true,
        path: '/app/flux/reports/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/app/flux/reports/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/app/flux/reports/:itemId/:tab',
        navigation: {
          current: 'Editare raport',
          links: [{ label: 'Lista rapoarte', url: '/app/flux/reports' }]
        },
        component: lazy(() => import('src/views/reports/ReportEditView'))
      },
      {
        exact: true,
        path: '/app/flux/probes',
        navigation: {
          current: 'Prelucrare probe'
        },
        component: lazy(() => import('src/views/probes/ProbesListView'))
      },
      {
        exact: true,
        path: '/app/flux/pv',
        navigation: {
          current: 'Registru PV'
        },
        component: lazy(() => import('src/views/pv-registry/PvReportListView'))
      },
      {
        exact: true,
        path: '/app/flux/probes-registry',
        navigation: {
          current: 'Registru receptie probe'
        },
        component: lazy(() => import('src/views/probes-registry/ProbesRegistryListView'))
      },
      {
        exact: true,
        path: '/app/flux/probes-press',
        navigation: {
          current: 'Registru de presa'
        },
        component: lazy(() => import('src/views/probes-press/ProbesPressListView'))
      },
      {
        exact: true,
        path: '/app/flux/worksheets-registry',
        navigation: {
          current: 'Registru fise de lucru'
        },
        component: lazy(() => import('src/views/worksheet-registry/WorksheetRegistryListView'))
      },
      {
        exact: true,
        path: '/app/flux/reports-registry',
        navigation: {
          current: 'Registru rapoarte'
        },
        component: lazy(() => import('src/views/report-registry/ReportRegistryListView'))
      },
      {
        exact: true,
        path: '/app/flux',
        component: () => <Redirect to="/app/flux/projects" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/flux" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/client',
    guard: AuthClientGuard,
    layout: ClientLayout,
    routes: [
      {
        exact: true,
        path: '/client/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/client/management/clients',
        navigation: {
          current: 'Lista clienti'
        },
        component: lazy(() => import('src/views-client/clients/ClientListView'))
      },
      {
        exact: true,
        path: '/client/management/clients/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/client/management/clients/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/client/management/clients/:itemId/:tab',
        navigation: {
          current: 'Vizualizare client',
          links: [{ label: 'Lista clienti', url: '/client/management/clients' }]
        },
        component: lazy(() => import('src/views-client/clients/ClientEditView'))
      },
      {
        exact: true,
        path: '/client/management/clients/:parentId/sites/:itemId',
        navigation: {
          current: 'Vizualizare santier client',
          links: [
            { label: 'Lista clienti', url: '/client/management/clients' },
            { label: 'Vizualizare client', url: '/client/management/clients', parent: true }
          ]
        },
        component: lazy(() => import('src/views-client/clients/ClientSiteEditView'))
      },
      {
        exact: true,
        path: '/client/management/sites',
        navigation: {
          current: 'Lista santiere'
        },
        component: lazy(() => import('src/views-client/sites/SiteListView'))
      },
      {
        exact: true,
        path: '/client/management/sites/:itemId',
        navigation: {
          current: 'Vizualizare santier',
          links: [{ label: 'Lista santiere', url: '/client/management/sites' }]
        },
        component: lazy(() => import('src/views-client/sites/SiteEditView'))
      },
      {
        exact: true,
        path: '/client/management',
        component: () => <Redirect to="/client/management/clients" />
      },
      {
        exact: true,
        path: '/client/flux/projects',
        navigation: {
          current: 'Lista proiecte'
        },
        component: lazy(() => import('src/views-client/projects/ProjectListView'))
      },
      {
        exact: true,
        path: '/client/flux/projects/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/client/flux/projects/${itemId}/invoices`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/client/flux/projects/:itemId/:tab',
        navigation: {
          current: 'Editare proiect',
          links: [{ label: 'Lista proiecte', url: '/client/flux/projects' }]
        },
        component: lazy(() => import('src/views-client/projects/ProjectEditView'))
      },
      {
        exact: true,
        path: '/client/flux',
        component: () => <Redirect to="/client/flux/projects" />
      },
      {
        exact: true,
        path: '/client',
        component: () => <Redirect to="/client/flux" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeGuard
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
