import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import DocumentForm from 'src/components/DocumentForm';
import Form from 'src/components/Form';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { DocumentAttributes, ProjectContract } from 'src/types/contract';
import { Probe, ProbeRegistryBody } from 'src/types/probe';
import { LabProduct } from 'src/types/product';
import { User } from 'src/types/user';
import * as Yup from 'yup';

import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  card: {}
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { probe: Probe; user: User; edit?: boolean };
}

interface AddRegistryForm {
  registryDate: string;
  registryManager: string;
  registryEvaluation: string;
}

const ProbeAddRegistryModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [probes, setProbes] = useState<Probe[]>([]);
  const [allProbes, setAllProbes] = useState<Probe[]>([]);
  const [cProbe, setCProbe] = useState<Probe>();
  const [template, setTemplate] = useState<ProjectContract>();
  const [attributes, setAttributes] = useState<DocumentAttributes>({});
  const [checkAllProbes, setCheckAllProbes] = useState<boolean>(extra.edit);
  const [products, setProducts] = useState<LabProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadDependences = useCallback(async () => {
    setLoading(true);
    try {
      const dbProbe = await api.probes.get(extra.probe?.id);
      const dbProbes = (await api.probes.listPg({ criteria: { pvpId: dbProbe.pvp?.id } })).result;
      const temp = await api.projectContracts.get(dbProbe.extra?.['extra.templateId']);
      const workstation = await api.workstations.get(dbProbe.extra?.['extra.workstationId']);
      const lab = await api.labs.get(workstation?.laboratory?.id);
      const prods = lab.laboratoryProducts?.filter(
        (lp) => lp.enabled && lp.product?.category?.id === dbProbe.extra?.['extra.categoryId']
      );
      setProducts(prods);
      setTemplate(temp);
      if (extra.edit) {
        setProbes([...dbProbes]);
      } else {
        setProbes([dbProbe]);
      }
      setAllProbes([...dbProbes]);
      setCProbe(dbProbe);
    } catch (e) {
      enqueueSnackbar('Problema incarcare probe', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [extra, enqueueSnackbar]);

  useEffect(() => {
    if (rest.visible) setTimeout(loadDependences, 100);
  }, [rest.visible, extra, loadDependences]);

  const addToRegistry = async (formData: AddRegistryForm): Promise<boolean> => {
    const probeRegistryBody: ProbeRegistryBody = {
      data: {
        registryDate: new Date(formData.registryDate),
        registryManager: formData.registryManager,
        registryEvaluation: formData.registryEvaluation
      },
      attributes,
      ids: probes.map((p) => p.id)
    };
    let response: { success: boolean };
    if (extra?.edit) {
      response = await api.probes.editRegistry(probeRegistryBody);
    } else {
      response = await api.probes.addToRegistry(probeRegistryBody);
    }
    if (!response?.success) {
      enqueueSnackbar('Problema receptie probe.', { variant: 'error' });
      throw new Error('No probes changed');
    }
    close()();
    return true;
  };

  const close = (withoutNotify?: boolean) => () => {
    rest.cancel();
    setTimeout(() => {
      if (!withoutNotify) rest.setNotify(new Date().getTime());
      setProbes([]);
    }, 500);
  };

  const handleChangeAllProbes = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newCheckAllProbes = !checkAllProbes;
    setCheckAllProbes(newCheckAllProbes);
    if (newCheckAllProbes) {
      setProbes([...allProbes]);
    } else {
      setProbes([cProbe]);
    }
  };

  const handleChangeProducts = (probeId: string | number, prods: LabProduct[]) => {
    const newAttributes = {
      ...attributes,
      [`probeReceive.${probeId}.products`]: prods.map((p) => p.product.name).join(', '),
      [`probeReceive.${probeId}.productsIds`]: prods.map((p) => p.id).join(', ')
    };
    setAttributes(newAttributes);
  };

  const probeString = probes.map((p) => p.pno).join(', ');
  const clientCompanyName = probes?.[0]?.clientCompanyName;
  const pvpString = extra?.probe?.pvp?.pno;
  const formDisabled = !probes.length;

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title={extra.edit ? 'Editare receptie probe' : 'Adauga receptie probe'}
          action={
            <IconButton aria-label="close" onClick={close(true)}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          {extra?.edit && (
            <Typography style={{ color: 'red', marginBottom: 20 }}>
              Atentie: se vor anula probele existente si se vor crea noi probe.
            </Typography>
          )}
          <Typography>
            <b>Client: </b>
            {clientCompanyName || 'Se incarca...'}
          </Typography>
          <Typography>
            <b>Proces verbal de prelevare:</b> {pvpString}
          </Typography>
          <Typography>
            <b>Probe:</b> {probeString || 'Se incarca...'}
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={checkAllProbes} onChange={handleChangeAllProbes} disabled={extra.edit} />}
            label="Receptioneaza toate probele din PV-ul curent"
          />
          <div style={{ marginTop: 30 }}>
            <Grid container spacing={4} style={{ marginBottom: 30 }}>
              {probes.map((np, index) => (
                <React.Fragment key={np.id}>
                  <DocumentForm
                    title={`Proba ${extra.edit ? `${index + 1} - inlocuieste #${np.pno}` : `#${np.pno}`}`}
                    data={attributes}
                    tempAttributes={template.contract.tempAttributes}
                    onChange={setAttributes}
                    dataKey="probeReceive."
                    index={extra.edit ? index + 1 : np.id}
                  />
                  <Grid item xs={12}>
                    <Autocomplete
                      id="tags-outlined"
                      multiple
                      options={products}
                      getOptionLabel={(option) => option?.product?.name}
                      // defaultValue={[]}
                      onChange={(e, value: LabProduct[]) => handleChangeProducts(extra.edit ? index + 1 : np.id, value)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Selecteaza servicii"
                          placeholder="Cauta dupa nume"
                        />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Form
              fields={[
                {
                  label: 'Data receptiei',
                  name: 'registryDate',
                  validation: Yup.date().required('Numele este obligatoriu'),
                  type: 'date',
                  initialValue: new Date().toISOString()
                },
                {
                  label: 'Nume executant',
                  name: 'registryManager',
                  validation: Yup.string().max(255).required('Numele este obligatoriu'),
                  initialValue: `${extra.user?.firstName} ${extra.user?.lastName}`
                },
                {
                  label: 'Starea probelor',
                  name: 'registryEvaluation',
                  validation: Yup.string().max(255).required('Numele este obligatoriu'),
                  initialValue: 'Buna'
                }
              ]}
              messages={{
                success: 'Receptie probe efectuata cu success',
                fail: 'Problema adaugare receptie probe'
              }}
              action={addToRegistry}
              submitLabel={extra?.edit ? 'Editeaza' : 'Adauga'}
              cardTitle="Recetie probe"
              onlyFields
              disabled={formDisabled}
              loading={loading}
            />
          </div>
        </CardContent>
      </Card>
    </DialogAdvanced>
  );
};

export default ProbeAddRegistryModal;
