import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FileText, Save, Send, XSquare } from 'react-feather';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import TextEditor from 'src/components/TextEditor';
import useGlobalModal, { MODALS } from 'src/hooks/useGlobalModal';
import api, { API_PDF_DOWNLOAD } from 'src/lib/api';
import { Theme } from 'src/theme';
import { Contract, ProjectContract } from 'src/types/contract';
import { Email } from 'src/types/email';
import downloadContractUtil from 'src/utils/downloadContract';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Label from './Label';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: {
    projectContractId: string;
    canEdit: boolean;
    viewMode?: boolean;
    title?: string;
  };
}

const TextEditorModal: FC<Props> = ({ extra, ...rest }) => {
  const editorRef: any = useRef();
  const classes = useStyles();
  const { openModal } = useGlobalModal();
  const { enqueueSnackbar } = useSnackbar();
  const { canEdit, viewMode } = extra;

  const [projectContract, setProjectContract] = useState<ProjectContract>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const closeModal = () => {
    rest.setNotify(null);
    rest.cancel();
  };

  const fetchContract = useCallback(async () => {
    if (extra.projectContractId) {
      setLoading(true);
      try {
        const contract = await api.projectContracts.get(extra.projectContractId);
        setProjectContract(contract);
      } catch (e) {
        enqueueSnackbar(`Eroare incarcare document: ${e.message}`, { variant: 'error' });
        closeModal();
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra.projectContractId]);

  useEffect(() => {
    fetchContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra.projectContractId]);

  const saveHandler = async () => {
    const data = editorRef.current?.getContent();
    const editContract: Contract = {
      content: data,
      project: projectContract.project,
      contractType: projectContract.contractType
    };
    try {
      await api.contracts.update(projectContract.contract.id, editContract);
      enqueueSnackbar('Document salvat cu success', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Eroare salvare Contract: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const onDownload = async () => {
    const url = `/api/contracts/pdf/${projectContract.contract.id}`;
    const { title } = projectContract.contract;
    const { version } = projectContract;
    setDownloadLoading(true);
    await downloadContractUtil(url, { title, version });
    setDownloadLoading(false);
  };

  const sendEmailHandler = () => {
    const email = new Email(projectContract.project, projectContract);
    openModal(MODALS.EMAIL, { email });
  };

  const revokeHandler = async () => {
    const messages = {
      success: 'Revocare raport cu succes!',
      failed: 'Problema revocare'
    };
    setLoading(true);

    try {
      const result = await api.projectContracts.revoke(projectContract?.id, { canceled: false });
      if (!result) throw new Error('Va rugam reincercati.');
      fetchContract();
      rest.setNotify(true);
      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogAdvanced {...rest} maxWidth="lg">
      <Card className={classes.bg}>
        <CardHeader
          title={
            <>
              {projectContract?.id ? `${extra.title} ${projectContract?.contract?.title}` : extra.title}{' '}
              {projectContract?.canceled && (
                <Label color="error">
                  <span style={{ fontSize: 15 }}>Anulat</span>
                </Label>
              )}
            </>
          }
          style={{ flexWrap: 'wrap' }}
          action={
            <div style={{ marginTop: 10 }}>
              {canEdit && !viewMode && projectContract?.canceled && (
                <Button
                  onClick={revokeHandler}
                  variant="contained"
                  size="small"
                  startIcon={<XSquare />}
                  loading={loading}
                >
                  <span>Revoca anularea</span>
                </Button>
              )}
              &nbsp;
              {canEdit && !viewMode && projectContract?.id && (
                <Button onClick={saveHandler} variant="contained" size="small" startIcon={<Save />}>
                  Salveaza
                </Button>
              )}
              &nbsp;
              {projectContract?.id && (
                <Button
                  onClick={onDownload}
                  variant="contained"
                  size="small"
                  startIcon={<FileText />}
                  loading={downloadLoading}
                >
                  PDF
                </Button>
              )}
              &nbsp;
              {projectContract?.id && (
                <Button onClick={sendEmailHandler} variant="contained" size="small" startIcon={<Send />}>
                  Email
                </Button>
              )}
              <IconButton aria-label="close" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div>
          }
        />
        <Divider />
        <CardContent style={{ height: 'calc(100vh - 150px)', padding: 10, paddingBottom: 0 }}>
          <Grid container spacing={4} style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
              <TextEditor
                ref={editorRef}
                content={projectContract?.contract?.content}
                attachmentApi={api.contracts.attachment}
                disabled={!canEdit}
                pdfLink={`${API_PDF_DOWNLOAD}/${projectContract?.contract?.id}`}
                viewMode={viewMode}
                height="100%"
                loading={loading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DialogAdvanced>
  );
};

export default TextEditorModal;
