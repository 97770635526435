/* eslint-disable max-classes-per-file */
/* eslint-disable lines-between-class-members */

import { CONTRACT_TYPE, ProjectContract } from './contract';
import { Project } from './project';

export enum EMAIL_STATUS {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export class Attachments {
  id: string;
  originalName: string;
}

export class Email {
  id?: string;

  from: string;

  to?: string;

  cc?: string;

  subject: string;

  title: string;

  content: string;

  footer: string;

  attachment?: string;

  attachments?: Attachments[];

  status?: EMAIL_STATUS;

  failedReason?: string;

  deletedAt?: string;

  created?: string;

  updated?: string;

  constructor(project?: Project, pc?: ProjectContract) {
    if (!project) return this;
    this.from = project.laboratory?.companyName;
    this.to = project.client?.legalEmail;
    this.cc = project.laboratory?.legalEmail;
    if (pc?.contractType === CONTRACT_TYPE.contract) {
      this.subject = `Contract ${pc.contract?.title}`;
      this.content = 'Regasiti in acest email contractul atasat. Multumim pentru colaborare.';
    } else if (pc?.contractType === CONTRACT_TYPE.offer) {
      this.subject = `Oferta ${pc.contract?.title}`;
      this.content = 'Regasiti in acest email oferta atasata. Multumim pentru colaborare.';
    } else if (pc?.contractType === CONTRACT_TYPE.order) {
      this.subject = `Comanda ${pc.contract?.title}`;
      this.content = 'Regasiti in acest email comanda atasata. Multumim pentru colaborare.';
    } else if (pc?.contractType === CONTRACT_TYPE.report) {
      this.subject = `Raport ${pc.contract?.title}`;
      this.content = 'Regasiti in acest email raportul atasat. Multumim pentru colaborare.';
    } else if (pc?.contractType === CONTRACT_TYPE.pvp) {
      this.subject = `PVP ${pc?.contract?.title}`;
      this.content = 'Regasiti in acest email procesul verbal de prelevare atasat. Multumim pentru colaborare.';
    }
    if (pc?.contract?.id) {
      this.attachment = pc?.contract?.id;
    }
    this.title = this.subject;
    this.footer = `Acest email a fost generat automat si contine date confidentiale. Nu dati direct reply la acest email. Pentru orice problema va rugam sa ne contactati la: ${
      project.laboratory?.legalEmail || ''
    }`;
  }
}
